<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      @save-button="saveButton()"
      @delete-button="deleteButton"
    ></table-component>
    <v-overlay :value="overlayLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import TableComponent from "@/components/TableComponent";
import Loader from "../components/Loader";
import power from "@/model/power.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      overlayLoading: false,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Название" },
          { id: 3, name: "KeyName" },
          { id: 4, name: "Описание" },
          // { id: 5, name: "Создан" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      POWER: "Power/STATE",
    }),
  },
  async mounted() {
    this.loading = true;
    await this.checkPower();
    await this.getPower();
    let model = new power();
    this.items.body = model.setBody(this.POWER.allPower);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getPower: "Power/GET_ALL_POWERS",
      deletePower: "Power/DELETE_POWER",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    saveButton() {
      let model = new test();
      let result = model.setInput(this.input);
      this.items.body.push({
        id: this.items.body[this.items.body.length - 1].id + 1,
        fields: [
          { id: 1, name: result.one },
          { id: 2, name: result.two },
          { id: 3, name: result.three },
          { id: 4, name: result.four },
          { id: 5, name: result.five },
        ],
      });
    },
    async deleteButton(item) {
      this.overlayLoading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deletePower(item[i].id);
        if (response.type === "success") {
          let chosenItemIndexInArray = this.items.body.findIndex((el) => {
            if (el.id === item[i].id) {
              return el.index;
            }
          });
          this.items.body.splice(chosenItemIndexInArray, 1);
        } else {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      this.overlayLoading = false;
    },
  },
};
</script>
