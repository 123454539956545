export default class test {
  constructor() {
    this.input = {
      id: null,
      name: "",
      keyName: "",
      description: "",
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.name = data.name;
      this.input.keyName = data.keyName;
      this.input.description = data.description;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].name,
              type: "string",
            },
            {
              id: 3,
              name: data[i].keyName,
              type: "string",
            },
            {
              id: 4,
              name: data[i].description,
              type: "string",
            },
            // {
            //   id: 5,
            //   name: data[i].createdAt.substr(0, 10),
            //   type: "string",
            // },
          ],
        });
      }
    }
    return this.body;
  }
}
